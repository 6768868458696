import { Environment } from '@bcf-shared-settings/settings/types';
import { environmentBase } from 'configs/environments/sportsbook/lvbetcom/environment.base';

export const environment: Environment = {
  ...environmentBase,
  production: true,
  userApiUrl: 'https://user.lvbet.com/',
  walletApiUrl: 'https://wallet.lvbet.com/',
  paymentApiUrl: 'https://payment.lvbet.com/',
  cmsApiUrl: {
    default: 'https://lvbet.com/sports/',
    mobile: 'https://m-sports.lvbet.com/'
  },
  cmsHeadlessApiUrl: 'https://cms.lvbet.com/',
  shadowStylesUrl: {
    default: 'https://lvbet-static.com/shadow-css/lvbet-com/sports/web/',
    'mobile-web': 'https://lvbet-static.com/shadow-css/lvbet-com/sports/mobile/',
    'mobile-capacitor': 'https://lvbet-static.com/shadow-css/lvbet-com/sports/mobile/'
  },
  payAndPlayAuthorization: {
    username: 'paymentinternalapi',
    password: 'prOd,135.1E4;',
    // if we want generate authorization key, we can use window.btoa function ex. `basic ${window.btoa('username:password')}`
    authorizationKey: 'basic cGF5bWVudGludGVybmFsYXBpOnByT2QsMTM1LjFFNDs='
  },
  feEnv: {
    baseUrl: 'https://lvbet.com',
    savePinLoginEndpoint: '/save-pin-login/',
    pinLoginEndpoint: '/pin-login/'
  }
};
