import { EnvironmentSports } from '@bcf-shared-settings/settings/types';
import { environmentBase } from 'configs/environments-sports/sportsbook/lvbetpl/environment.base';

export const environment: EnvironmentSports = {
  ...environmentBase,
  matchOfferApiUrl: 'https://offer.lvbet.com/client-api/v5/',
  matchOfferSocketUrl: 'wss://offer-ws.lvbet.com/_v5/ws/update/',
  matchOfferSocketMatchStatsUrl: 'wss://offer-ws-statistics.lvbet.com/_v1/',
  bpApiUrl: 'https://betslips.lvbet.com/client-betslips/v1/',
  bpApiUrlSystem: 'https://settings.lvbet.com/client-system/v1/',
  resultsCenterApiUrl: 'https://results-center.beefee.co.uk/results-center/v3/'
};
